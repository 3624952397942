export const checksums = {
  "content_page_en": "I263mDBWQn",
  "content_page_fr": "qgO76vWgRn"
}

export const tables = {
  "content_page_en": "_content_content_page_en",
  "content_page_fr": "_content_content_page_fr",
  "info": "_content_info"
}

export default {
  "content_page_en": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "content_page_fr": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "info": {
    "fields": {}
  }
}