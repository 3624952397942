export const BlogPostHeaderImg = () => import('./../../components/content/BlogPostHeaderImg.vue')
export const BlogPostList = () => import('./../../components/content/BlogPostList.vue')
export const DocDate = () => import('./../../components/content/DocDate.vue')
export const PageChildren = () => import('./../../components/content/PageChildren.vue')
export const PageTocList = () => import('./../../components/content/PageTocList.vue')
export const ProseStrong = () => import('./../../components/content/ProseStrong.vue')
export const QueryCollection = () => import('./../../components/content/QueryCollection.vue')
export const QueryNavigation = () => import('./../../components/content/QueryNavigation.vue')
export const QueryPlayground = () => import('./../../components/content/QueryPlayground.vue')
export const SlotInjection = () => import('./../../components/content/SlotInjection.vue')
export const globalComponents: string[] = ["ProseA","ProseBlockquote","ProseCode","ProseEm","ProseH1","ProseH2","ProseH3","ProseH4","ProseH5","ProseH6","ProseHr","ProseImg","ProseLi","ProseOl","ProseP","ProsePre","ProseScript","ProseTable","ProseTbody","ProseTd","ProseTh","ProseThead","ProseTr","ProseUl"]
export const localComponents: string[] = ["BlogPostHeaderImg","BlogPostList","DocDate","PageChildren","PageTocList","ProseStrong","QueryCollection","QueryNavigation","QueryPlayground","SlotInjection"]