<script setup lang="ts">
import { withLeadingSlash } from 'ufo'
import type { Collections } from '@nuxt/content'

definePageMeta({
  layout: 'site',
})

const route = useRoute()
const router = useRouter()
const { locale, localeProperties } = useI18n()

const path = computed(() => withLeadingSlash(String(route.params.slug).replaceAll(',', '/')))

const { data: page } = await useAsyncData(
  'content:page:' + route.path,
  async () => {
    const collection = ('content_page_' + locale.value) as keyof Collections
    const content = await queryCollection(collection).path(path.value).first()

    // Possibly fallback to default locale if content is missing in non-default locale
    if (!content) {
      const defaultContent = await queryCollection('content_page_en').path(path.value).first()
      if (defaultContent) return defaultContent
    }

    return content
  },
  { watch: [locale] },
)

if (!page.value) router.push('/404')

// set SEO meta
useSeoMeta({
  title: () => (page.value?.title ? page.value?.title : ''),
  ogTitle: () => (page.value?.title ? page.value?.title : ''),
  description: () => (page.value?.description ? page.value?.description : ''),
  ogDescription: () => (page.value?.description ? page.value?.description : ''),
  ogImage: () => page.value?.image,
})

/* scroll to anchor if route contains hash #[id] */
/*
function scrollToAnchor() {
  if (route.hash) {
    const elt = document.getElementById(route.hash.substring(1))
    const { scrollToHTMLElement } = useWindowScrollToElement()
    if (elt) scrollToHTMLElement(elt, useAppConfig().siteConfig.headerHeight)
  }
}
*/

onMounted(() => {
  // commented as scroll to anchor on page load is working natively since nuxt 3.10.1
  // scrollToAnchor()
})
</script>

<template>
  <!-- FOR DEBUGGING -->
  <!-- <pre>
    {{ page }}
  </pre> -->

  <main v-if="page" class="dvn-site-content">
    <section class="dvn-section-content dvn-nuxt-content">
      <ContentRenderer v-if="page" :dir="localeProperties?.dir ?? 'ltr'" :value="page" />
    </section>
  </main>
</template>

<style lang="css">
@import url('@styles/site/index.css');
@import url('@styles/site/nuxt-content.css');
</style>
